@use '@angular/material' as mat;

@include mat.core();

// HERTA TYPOGRAPHY

$my-custom-typography-config: mat.define-typography-config(
  $font-family: 'Urbanist',
  // Add other typography properties here (e.g., font-size, font-weight, etc.)
);

*:not(mat-icon) {
  font-family: 'Urbanist', 'sans-serif';  
}

mat-icon {
  font-family: 'Material Icons Round', 'Urbanist', 'sans-serif';
}

// Global Colors Palette
$countify-primary-logo: #02BEBE !important;
$countify-secondary-logo: #275C9A !important; 
$countify-tertiary-logo: #009CEA !important; 
$herta-text-color: #6f797a !important;
$herta-text-deep-color: #484c4c !important;
$herta-gray-logo: #b1b1b1 !important;
$herta-lightcomp-color: #b3c5ff !important;
$herta-comp-background: #edf0fe !important;
$countify-warn: #a80710 !important;

// Countify Charts Color Palette 
$countify-chart-1: #02BEBE !important;
$countify-chart-1: #275C9A !important; 
$countify-chart-1: #009CEA !important; 
$countify-chart-1: #A1C9FF !important; 
$countify-chart-1: #b1b1b1 !important;

// Color Theme
$herta-primary-palette: (
  50: #F1FFFE,
  100: #ADFFFE,
  200: #6FF7F7,
  300: #4CDADA,
  400: #02BEBE,
  500: #00A1A1,
  600: #008585,
  700: #004F50,
  800: #003737,
  900: rgba(black, 0.87),
  contrast: (
    50: #F1FFFE,
    100: #ADFFFE,
    200: #6FF7F7,
    300: #4CDADA,
    400: #02BEBE,
    500: #00A1A1,
    600: #008585,
    700: #004F50,
    800: #003737,
    900: rgba(black, 0.87),
  ),
);

$herta-secondary-palette: (
  50: #EDF0FE,
  100: #D3E4FF,
  200: #A1C9FF,
  300: #72AEF8,
  400: #5694DB,
  500: #387ABF,
  600: #275C9A,
  700: #004880,
  800: #00325B,
  900: rgba(black, 0.87),
  contrast: (
    50: #EDF0FE,
    100: #D3E4FF,
    200: #A1C9FF,
    300: #72AEF8,
    400: #5694DB,
    500: #387ABF,
    600: #275C9A,
    700: #004880,
    800: #00325B,
    900: rgba(black, 0.87),
  ),
);

$herta-error-palette: (
  50: #ffedea,
  100: #FF9800,
  200: #ff897d,
  300: #de3730,
  400: #ba1a1a,
  500: #a80710,
  600: #93000a,
  700: #7e0007,
  800: #410002,
  900: rgba(black, 0.87),
  contrast: (
    50: #ffedea,
    100: #FF9800,
    200: #ff897d,
    300: #de3730,
    400: #ba1a1a,
    500: #a80710,
    600: #93000a,
    700: #7e0007,
    800: #410002,
    900: rgba(black, 0.87),
  ),
);

$my-primary: mat.define-palette($herta-primary-palette, 400, 300, 900);
$my-accent: mat.define-palette($herta-secondary-palette, 600, 100, 400);
$my-warn: mat.define-palette($herta-error-palette, 500);
$my-alert: mat.define-palette($herta-error-palette, 100);
// HERTA DEFINE THEME

$theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
      alert: $my-alert,
    ),
    typography: $my-custom-typography-config,
    density: 0,
  )
);

// Adds density level 0 styles
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($my-custom-typography-config);


:root {

  --font-herta: "Urbanist";

  --countify-chart-1: #02BEBE;
  --countify-chart-2: #275C9A;
  --countify-chart-3: #009CEA;
  --countify-chart-4: #A1C9FF;
  --countify-chart-5: #b1b1b1;

  --countify-primary-logo: #02BEBE;
  --countify-secondary-logo: #275C9A ;
  --countify-tertiary-logo: #009CEA ;
  --herta-text-color: #6f797a;
  --herta-gray-logo: #b1b1b1;
  --herta-lightcomp-color: #b3c5ff;
  --herta-comp-background: #edf0fe;


  --herta-error-palette-50: #ffedea;
  --herta-error-palette-100: #ffdad6;
  --herta-error-palette-200: #ff897d;
  --herta-error-palette-300: #de3730;
  --herta-error-palette-400: #ba1a1a;
  --herta-error-palette-500: #a80710;
  --herta-error-palette-600: #93000a;
  --herta-error-palette-700: #7e0007;
  --herta-error-palette-800: #410002;
  --herta-error-palette-900: rgba(black, 0.87);
}