/* GENERAL BUTTONS COMPONENTS */
.submit-button {
  border-radius: 20px 20px 20px 20px;
  background-color: $countify-primary-logo;
  color: $countify-primary-logo;
  width: 10vw;
}

.apply-buttons {
    width: 124px;
    border-radius: 20px !important;
  }

.clear-buttons {
  width: 124px;
  border-radius: 20px !important;
}

.button-row {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

/* On the register sheets */

.checkbox-settings {
  display: flex;
  align-items: flex-start;
  padding-bottom: 4vh;
}
