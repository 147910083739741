@use '@angular/material' as mat;
/* TABLE COMPONENTS */

/* On the list sheets */
.full-width-table {
  height: 100%;
}

th.mat-mdc-header-cell {
  background-color: #dae1ff !important;
  font-size: 0.86rem;
}

td {
  word-wrap: break-word;
  word-break: break-all;
}

.mdc-data-table__row:hover {
  background-color: $herta-comp-background;
}

.icon-table {
  color: $countify-primary-logo;
  font-size: large;
  cursor: pointer;
}

.icon-table:hover {
  color: $herta-gray-logo;
}

.header-table-text {
  color: $countify-primary-logo;
  text-transform: uppercase;
  background-color: $herta-comp-background;
}

.mat-mdc-table tbody, .mat-mdc-table tfoot, .mat-mdc-table thead, .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row, .mat-mdc-table .mat-mdc-header-cell {
  background: inherit;
  height: 40px !important;
}

.mat-mdc-paginator {
  display: flex !important;
  color: var(--mat-paginator-container-text-color);
  justify-content: flex-end;
  height: 40px !important;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  .mat-mdc-text-field-wrapper {
    height: 30px !important;
    align-items: center !important;
    flex: auto;
}
}