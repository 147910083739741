/*  SCREEN MEDIA QUERY MIXINS COMPONENTS */

// Dispositivos muy pequeños (teléfonos)
@media only screen and (max-width: 320px) {
  body {
    font-size: 125%;
    // Otros estilos para pantallas muy pequeñas
  }
}

// Teléfonos y dispositivos pequeños
@media only screen and (max-width: 480px) {
  body {
    font-size: 125%;
    // Otros estilos para pantallas pequeñas
  }
}

// Tablets y dispositivos medianos
@media only screen and (max-width: 768px) {
  body {
    font-size: 110%;
    // Otros estilos para pantallas medianas
  }
}

// Tablets en orientación horizontal y pantallas de tamaño mediano a grande
@media only screen and (max-width: 1024px) {
  body {
    font-size: 100%;
    // Otros estilos para pantallas grandes
  }
}

// Laptops y computadoras de escritorio
@media only screen and (max-width: 1280px) {
  body {
    font-size: 100%;
    // Otros estilos para pantallas extra grandes
  }
}

// Pantallas más grandes, como monitores de alta resolución
@media only screen and (min-width: 1600px) {
  body {
    font-size: 100%;
    // Otros estilos para pantallas muy grandes
  }
}

/*  RESPONSIVE FLEX LAYOUTS */

/* Using FlexLayout */
[row] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 999px) {
  [row] {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

[column] {
  display: flex;
  flex-direction: column;
}

[flex-1] {
  flex: 1 1 auto;
}

[flex-auto] {
  flex: 1 1 auto;
}

[flex-initial] {
  flex: 0 1 auto;
}

[flex-none] {
  flex: none;
}
