/* BRAND COMPONENTS */

/* On the main navigation */


.logo-title-head-light {
  color: $countify-primary-logo;
  font-weight: lighter;
  font-size: xx-large;
}

.logo-title-head-bold {
  color: $herta-text-color;
  font-weight: bold;
  font-size: xx-large;
}


