/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  background-color: white;
}

body {
  margin: 0;
}

