/*  GENERAL LINKS COMPONENTS */

.link-click-dark {
  @include link-animation-styles(179, 197, 255);
}

/*  On the main navigation  */

.icon-social-footer {
  @include link-hover-styles;

  display: flex;
  height: 20px;
  width: 20px;
  padding-left: 0.5%;

  &:hover {
    transition: 0.8s ease-in-out;
    height: 18px;
    width: 18px;
  }
}


