/* NAV SITE COMPONENTS */

/* On the main navigation */
/* Head nav */
.nav-header {
  background-color: $herta-comp-background;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.example-sidenav-content {
  display: flex;
  height: 100%;
  margin-left: inherit;
}

.space-elements-header {
  flex: 1 1 auto;
}

/* Side nav */
.side-nav-background {
  background-color: $herta-comp-background;
  border-right: none;
}

.side-nav-elements {
  position: fixed;
  top: 60px;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}

/* Footer nav */
.nav-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $herta-comp-background;
  color: $herta-text-color;
  font-size: small;
  padding-right: 2%;
  height: 20px;
}
