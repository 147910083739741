/* LINKS MIXINS COMPONENTS */

@mixin link-animation-styles($r, $g, $b) {
  $color: rgba($r, $g, $b, 0);

  cursor: pointer;
  filter: drop-shadow(0 0 5px $color);
  transition: filter 0.5s ease-in-out;

  &:hover {
    $color-hover: rgba($r, $g, $b, 1);
    filter: drop-shadow(0 0 20px $color-hover);
  }
}

@mixin link-hover-styles {
  cursor: pointer;
  transition: filter 0.5s ease-in-out;

  &:hover {
    filter: opacity(60%);
  }
}
