/*  DIALOG COMPONENTS */
.mat-mdc-dialog-content {
  padding: 20px 24px 0 24px !important;
}

.mat-mdc-dialog-actions {
  justify-content: right !important;
  padding: 0 24px 24px 24px !important;
}

.full-width {
  height: 100% !important;
  display: block !important;
}

.image-outline {
  border: 1px solid rgba(0, 0, 0, 0.54);
}


