/*  GENERAL SITE TEXT */

.title-header-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0 20px 0;
}

.title-head {
  color: $countify-primary-logo;
  font-family: 'urbanist', sans-serif;
  font-size: 24px;
  font-style:normal;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 20px;
}

.title-card {
  color: $countify-secondary-logo;
  font-family: 'urbanist', sans-serif;
  font-size: 20px;
  font-style:normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 10px;
}

.subtitle-card {
  color: $herta-text-color;
  font-family: 'urbanist', sans-serif;
  font-size: 16px;
  font-style:normal;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 10px;
}

/*  On the main navigation  */
.text-account {
  color: $herta-text-color;
  font-size: small;
}

/*  On the table sheet  */
.header-table-text {
  color: $countify-primary-logo;
  text-transform: uppercase;
  background-color: $herta-comp-background;
}

/*  On the register sheets  */
.text-hint {
  font-size: small;
  color: $herta-text-color;
}