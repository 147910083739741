/*  GENERAL SITE COMPONENTS */
/*  general icons on light mood  */

.icon-blue {
  color: $countify-secondary-logo;
}

.icon-gray {
  color: $herta-gray-logo;
}

.icon-textgray {
  color: $herta-text-color;
}

.icon-primary {
  color: $countify-primary-logo;
}

.icon-secondary {
  color: $countify-secondary-logo;
}

.icon-tertiary {
  color: $countify-tertiary-logo;
}

.icon-warn {
  color:  $countify-warn;
}

.icon-light-blue {
  color: $herta-lightcomp-color;
}

.icon-primary {
  color: $countify-primary-logo;
}

.icon-secondary {
  color: $countify-secondary-logo;
}

.icon-tertiary {
  color: $countify-tertiary-logo;
}

/*  On the login sheet  */

.icon-to-espansion {
  @include link-hover-styles;

  color: $countify-secondary-logo;
}
