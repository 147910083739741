/* ON THE GENERAL SITE CARDS */
/* Body Container */
.grid-container {
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%;
}

/* Change the border color */
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: #000e33 !important;
  color: #000e33 !important;
}

mat-form-field input:disabled {
  color: #000e33 !important;
}

/* Cards without decoration */
mat-card.card-naked {
  background: none;
  border-style: none;
  outline: none;
  box-shadow: none;
}
/* On the main navigation */
.space-elements-header {
  flex: 1 1 auto;
}